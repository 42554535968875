import { getIdentityAuth } from '@guardian/identity-auth-frontend';
import { getCookie } from '@guardian/libs';
export async function isSignedInWithOktaAuthState() {
    return getIdentityAuth()
        .isSignedInWithAuthState()
        .catch((e) => {
        // eslint-disable-next-line no-console -- we want to log the error to console, not Sentry
        console.error(e);
        return {
            isAuthenticated: false,
            accessToken: undefined,
            idToken: undefined,
        };
    });
}
let userFromCookieCache = null;
const cookieName = 'GU_U';
const getUserCookie = () => getCookie({ name: cookieName, shouldMemoize: true });
const decodeBase64 = (str) => decodeURIComponent(escape(window.atob(str.replace(/-/g, '+').replace(/_/g, '/').replace(/,/g, '='))));
const getUserFromCookie = () => {
    if (userFromCookieCache === null) {
        const cookieData = getUserCookie();
        if (cookieData) {
            try {
                const userData = JSON.parse(decodeBase64(cookieData.split('.')[0] ?? ''));
                const id = parseInt(userData[0] ?? '', 10);
                const displayName = decodeURIComponent(userData[2] ?? '');
                const accountCreatedDate = userData[6];
                const userEmailValidated = Boolean(userData[7]);
                if (id && accountCreatedDate) {
                    userFromCookieCache = {
                        id,
                        publicFields: {
                            displayName,
                        },
                        dates: { accountCreatedDate },
                        statusFields: {
                            userEmailValidated,
                        },
                        rawResponse: cookieData,
                    };
                }
            }
            catch (e) {
                // eslint-disable-next-line no-console -- we want to log the error to console, not Sentry
                console.error('Cookie value is malformed', e);
            }
        }
    }
    return userFromCookieCache;
};
export const isUserLoggedIn = () => getUserFromCookie() !== null;
export const getOptionsHeadersWithOkta = (authStatus) => {
    if (authStatus.kind === 'SignedInWithCookies') {
        return {
            credentials: 'include',
        };
    }
    return {
        headers: {
            Authorization: `Bearer ${authStatus.accessToken.accessToken}`,
            'X-GU-IS-OAUTH': 'true',
        },
    };
};
export async function getAuthState() {
    const authState = await isSignedInWithOktaAuthState();
    return authState;
}
export async function eitherInOktaTestOrElse(inOktaTestFunction, notInOktaTestFunction) {
    const useOkta = !!window.guardian.config.switches.okta;
    if (useOkta) {
        const authState = await getAuthState();
        return inOktaTestFunction(authState);
    }
    else {
        return notInOktaTestFunction();
    }
}
export function getSignedInStatusWithOkta(authState) {
    if (authState.isAuthenticated) {
        return {
            kind: 'SignedInWithOkta',
            accessToken: authState.accessToken,
            idToken: authState.idToken,
        };
    }
    return { kind: 'SignedOutWithOkta' };
}
export const isUserLoggedInOktaRefactor = () => getAuthStatus().then((authStatus) => authStatus.kind === 'SignedInWithCookies' ||
    authStatus.kind === 'SignedInWithOkta'
    ? true
    : false);
export function getSignedInStatusWithCookies() {
    const GU_UCookie = getUserCookie();
    return GU_UCookie === null || GU_UCookie === ''
        ? { kind: 'SignedOutWithCookies' }
        : { kind: 'SignedInWithCookies' };
}
export const getAuthStatus = async () => {
    const useOkta = !!window.guardian.config.switches.okta;
    if (useOkta) {
        const authState = await getAuthState();
        return getSignedInStatusWithOkta(authState);
    }
    else {
        return getSignedInStatusWithCookies();
    }
};
