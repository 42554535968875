import { log } from '@guardian/libs';
import { schedule } from '../lib/scheduler';
const isPolyfilled = new Promise((resolve) => {
    if (window.guardian.mustardCut || window.guardian.polyfilled) {
        return resolve();
    }
    window.guardian.queue.push(resolve);
});
export const startup = async (name, task, options) => {
    await isPolyfilled;
    log('dotcom', `🎬 booting ${name}`);
    await schedule(name, task, options);
    log('dotcom', `🥾 booted ${name}`);
};
