import { isOneOf, startPerformanceMeasure } from '@guardian/libs';
const START = Date.now();
/**
 * Keeps a count of how many tasks are currently running, so we can manage concurrency.
 */
let RUNNING_TASK_COUNT = 0;
/**
 * The maximum amount of concurrent tasks.
 * @default Infinity
 */
let CONCURRENCY_COUNT = Infinity;
/**
 * Possible task priorities.
 *
 * These are the Guardian's priorities, not the user's or browser's.
 *
 * The order of this array is important. Tasks are batched according to these
 * priorities, and the scheduler will prefer the priority with the lowest index.
 **/
const PRIORITIES = ['critical', 'feature', 'enhancement'];
export const isValidSchedulerPriority = isOneOf(PRIORITIES);
/**
 * Stores scheduled tasks in a queue, grouped by priority.
 */
const queue = {
    critical: { lastStartTime: Infinity, tasks: [] },
    feature: { lastStartTime: Infinity, tasks: [] },
    enhancement: { lastStartTime: Infinity, tasks: [] },
};
/**
 * Checks whether there's spare task-running capacity.
 */
function atConcurrencyLimit() {
    return RUNNING_TASK_COUNT === CONCURRENCY_COUNT;
}
/**
 * Gets the next task to run, according to priority and the order they were
 * scheduled.
 */
function getNextTask() {
    if (atConcurrencyLimit())
        return undefined;
    const runningTime = Date.now() - START;
    for (const priority of PRIORITIES) {
        const { lastStartTime, tasks } = queue[priority];
        const nextTask = tasks.shift();
        if (runningTime <= lastStartTime && nextTask?.canRun({ runningTime })) {
            return nextTask;
        }
    }
    // found no suitable tasks, so return undefined
    return undefined;
}
/**
 * Handles running tasks.
 *
 * Gets the next eligible task from the queue, executes it, then calls the
 * task's resolver with the result.
 *
 * If there are no tasks to run, or the maximum number of tasks are already
 * running, then it does nothing.
 */
function run() {
    const nextTask = getNextTask();
    if (!nextTask)
        return;
    RUNNING_TASK_COUNT++;
    const { name, task, resolver } = nextTask;
    const { endPerformanceMeasure } = startPerformanceMeasure('dotcom', 'scheduler', name);
    task()
        .then(resolver)
        .catch(console.error)
        .finally(() => {
        endPerformanceMeasure();
        RUNNING_TASK_COUNT--;
        run();
    });
}
/**
 * Schedules a task for execution.
 * @param name Human name for the task, used for logging etc.
 * @param task The thing you want to do. This should be a function that
 * returns a promise. Make sure its an _honest_ promise! If you just return
 * `Promise.resolve()` to keep the compiler happy, then the scheduler will
 * think the task instantly completed and start the next one too soon.
 * @param options Options for scheduling a task.
 * @param {Priority} options.priority Priority of the task. Tasks with
 * higher priority will be run first. Defaults to `'standard'`.
 * @returns A promise that resolves when the task completes.
 */
export async function schedule(name, task, { priority, canRun = () => true }) {
    const queueableTask = { name, task, canRun };
    const scheduledTask = new Promise((resolve) => (queueableTask.resolver = resolve));
    // we know this is safe because we know function in the new Promise above
    // is synchronous (even if TS doesn't)
    queue[priority].tasks.push(queueableTask);
    run();
    return scheduledTask;
}
export function setSchedulerConcurrency(concurrency) {
    CONCURRENCY_COUNT = concurrency;
}
/**
 * Set the number of milliseconds since the scheduler was initialised
 * after which tasks of a given priority will no longer be started.
 *
 * Defaults to `Infinity` for all priorities.
 */
export function setSchedulerPriorityLastStartTime(priority, lastStartTime) {
    queue[priority].lastStartTime = lastStartTime;
    run();
}
