let config;
/**
 * Reads the config from a JSON script tag,
 * or reuse the memoised value if it exists.
 *
 * @returns {Config} an immutable, global config
 */
export const getConfig = () => {
    if (config)
        return config;
    const serialised = document.querySelector('script#config')?.innerHTML;
    try {
        if (!serialised) {
            throw Error('Unable to fetch config attribute from #config');
        }
        else {
            config = JSON.parse(serialised);
            return config;
        }
    }
    catch (error) {
        console.error(`🚨 Error parsing config. Is this data serialisable? ${String(serialised)} 🚨`);
        throw error;
    }
};
