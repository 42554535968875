import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { isUndefined } from '@guardian/libs';
import { createContext, useContext } from 'react';
/**
 * Context for global, static, immutable values (application configuration)
 * This should not contain anything which will change between re-renders.
 *
 * It is deliberately set with a default context of `undefined` to better
 * surface errors relating to incorrect usage.
 *
 * It is deliberately not exported
 * @see https://kentcdodds.com/blog/how-to-use-react-context-effectively
 */
const ConfigContext = createContext(undefined);
/**
 * ConfigProvider component should be included at a high level
 * so that lower down components can access the context value
 */
export const ConfigProvider = ({ value, children, }) => _jsx(ConfigContext.Provider, { value: value, children: children });
/**
 * useContext hook for safely fetching application configuration.
 * Ensures that it is used within the relevant Context.Provider
 */
export const useConfig = () => {
    const context = useContext(ConfigContext);
    if (isUndefined(context)) {
        throw Error('useConfig must be used within the ConfigContext provider');
    }
    return context;
};
